import { Injectable } from '@angular/core';
// import { ChatDB } from '../data/chat/chat';
// import { ChatUsers, chat } from '../model/chat.model';
import { Observable, Subscriber } from 'rxjs';
import { AppService } from '../../app/app.service';

import { map, filter, scan, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment'
var today = new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

export interface Modele {
  id: number;
  libelle: string;
  code: string;
  created_at: string | null;
  updated_at: string | null;
  marque_id: number;
}
@Injectable({
  providedIn: 'root'
})

export class ParameterService {

  public observer: Subscriber<{}>;


  constructor(private http: HttpClient, private appService: AppService) { }

  /*------------------START GET METHODE-------------------*/

  //modifier contrat
  updateContrat(item: any): Observable<any> {
    return this.http.put<any[]>(`${this.appService.getBaseUrl()}contrat/${item.id}`, item, this.appService.httpOptions)
      .pipe(
        catchError(this.appService.handleError<any[]>(``))
      )
  }

  //listing Des contrats
  getContratList(): Observable<any> {
    return this.http.get<any[]>(`${this.appService.getBaseUrl()}contrats`, this.appService.httpOptions)
      .pipe(
        catchError(this.appService.handleError<any[]>(``))
      )
  }//fin getVideoList
  //end modifier contrat
  //listing Des avis
  getAvisList(): Observable<any> {
    return this.http.get<any[]>(`${this.appService.getBaseUrl()}avis/all`, this.appService.httpOptions)
      .pipe(
        catchError(this.appService.handleError<any[]>(``))
      )
  }//fin getAvisList

  //getVisiteAbonneList
  getVisiteAbonneList(item: any): Observable<any> {
    return this.http.get<any[]>(`${this.appService.getBaseUrl()}visites/${item.id}`, this.appService.httpOptions)
      .pipe(
        catchError(this.appService.handleError<any[]>(``))
      )
  }//fin getVisiteAbonneList

  getRedateur(): Observable<any> {
    return this.http.get<any[]>(`${this.appService.getBaseUrl()}redacteurs`, this.appService.httpOptions)
      .pipe(
        catchError(this.appService.handleError<any[]>(``))
      )
  }//fin getRedateur





  //getDonAbonneList
  getDonAbonneList(item: any): Observable<any> {
    return this.http.get<any[]>(`${this.appService.getBaseUrl()}dons/${item.id}`, this.appService.httpOptions)
      .pipe(
        catchError(this.appService.handleError<any[]>(``))
      )
  }//fin getDonAbonneList

  // get type intervention
  getTypeIntervention(): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/listing-type-interventions');
  }

  //create type intervention
  addTypeIntervention(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'params/save-type-intervention', item)
  }

  //delete type intervention
  deleteTypeIntervention(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'params/delete-type-intervention', item)
  }

  //edit type intervention
  editTypeIntervention(item: any): Observable<any> {
    return this.http.put(environment.apiUrl + 'params/update-type-intervention', item)
  }
  //end type d'intervention

  //moyen d'appel
  // get moyen appel
  getMoyenApel(): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/listing-moyen-appel');
  }
  //create moyen d'appel
  addMoyenAppel(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'params/save-moyen-appel', item)
  }
  //delete moyen d'appel
  deleteMoyenAppel(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'params/delete-moyen-appel', item)
  }
  //edit moyen d'appel
  editMoyenAppel(item: any): Observable<any> {
    return this.http.put(environment.apiUrl + 'params/update-moyen-appel', item)
  }
  //end moyen d'appel


  //hopital
  // get hopital
  getDepotremplit(): Observable<any> {
    return this.http.get(environment.apiUrl + 'depot-rempli');
  }
  //create hopital
  addHopital(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'params/save-hopital', item)
  }
  //delete type intervention
  deleteHopital(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'params/delete-hopital', item)
  }

  //edit type iintervention
  editHopital(item: any): Observable<any> {
    return this.http.put(environment.apiUrl + 'params/update-hopital', item)
  }
  //end hopital


  //groupement

  // get groupement
  getGroupement(): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/listing-groupement');
  }
  // add groupement
  addGroupement(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'params/save-groupement', item)
  }
  // add groupement
  deleteGroupement(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'params/delete-groupement', item)
  }
  // add groupement
  editGroupement(item: any): Observable<any> {
    return this.http.put(environment.apiUrl + 'params/update-groupement', item)
  }
  //end groupement

  //compagnie

  // get compagnie
  getCompagnie(): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/listing-compagny');
  }
  //create compagnie
  addCompagnie(item): Observable<any> {
    return this.http.post(environment.apiUrl + 'params/save-compagny', item);
  }
  //delete compagnie
  deleteCompagnie(item): Observable<any> {
    return this.http.post(environment.apiUrl + 'params/delete-compagny', item);
  }
  //edit compagnie
  editAnnonceur(item: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}admin/update_annonceur/${item.id}`, item);
  }


  //end compagnie

  //caserne

  // get caserne
  getdepotvide(): Observable<any> {
    return this.http.get(environment.apiUrl + 'depot-vide');
  }
  //add caserne
  addCaserne(item): Observable<any> {
    return this.http.post(environment.apiUrl + 'params/save-caserne', item);
  }
  //add caserne
  deleteCaserne(item): Observable<any> {
    return this.http.post(environment.apiUrl + 'params/delete-caserne', item);
  }
  //add caserne
  editCaserne(item): Observable<any> {
    return this.http.put(environment.apiUrl + 'params/update-caserne', item);
  }


  //end caserne 
  //get departement 
  getDepartement(): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/listing-departement');
  }

  //get role
  getRole(): Observable<any> {
    return this.http.get(environment.apiUrl + 'interventions/get-role');
  }
  getNiveau(): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/niveau');
  }


  //get alerte motivées

  //alerte motivée

  //get liste profil
  getListProfil(item): Observable<any> {
    return this.http.post(environment.apiUrl + 'admin/profils', item);
  }
  //add caserne
  updateVehiculeChange(item): Observable<any> {
    return this.http.post(environment.apiUrl + 'offer/update-vehicule', item);
  }

  updatephotochange(item): Observable<any> {
    return this.http.post(environment.apiUrl + 'offer/update-photo', item);
  }
  //add caserne
  deleteProfil(item): Observable<any> {
    return this.http.post(environment.apiUrl + 'admin/remove-profi', item);
  }
  //add caserne
  editAlerteMotive(item): Observable<any> {
    return this.http.put(environment.apiUrl + 'params/update/typealertemotivee ', item);
  }

  searchVictims(item): Observable<any> {
    return this.http.post(environment.apiUrl + 'params/get-victims-by-name ', item);
  }

  searchProfile(item): Observable<any> {
    return this.http.post(environment.apiUrl + 'params/listing-profil-by-name ', item);
  }



  getCategorieData(): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/categories ');
  }
  getTypeData(): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/type-vehicule');
  }

  getModeleData(): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/modeles');
  }

  // debut marque

  getMarqueData(): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/marques');
  }


  getModelData(): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/modeles');
  }

  // getMarqueDataById
  getMarqueDataById(marqueId: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/marques/' + marqueId);

  }
  //updateMarque

  updateMarque(id, data): Observable<any> {
    return this.http.put(environment.apiUrl + 'params/update_marques/' + id, data);
  }
  //
  //insertMarque
  insertMarque(item): Observable<any> {
    return this.http.post(environment.apiUrl + 'params/add_marques', item);
  }
  //
  //deleteMarque
  deleteMarque(marqueId: number): Observable<any> {
    return this.http.delete(environment.apiUrl + 'params/delete_marques/' + marqueId);
  }

  updateModel(id, data): Observable<any> {
    return this.http.put(environment.apiUrl + 'params/update_models/' + id, data);
  }
  //
  //insertMarque
  insertModel(item): Observable<any> {
    return this.http.post(environment.apiUrl + 'params/add_models', item);
  }
  //
  //deleteMarque
  deleteModel(marqueId: number): Observable<any> {
    return this.http.delete(environment.apiUrl + 'params/delete_models/' + marqueId);
  }

  // end marque

  // Debut ville


  getVilleData(): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/villes');
  }
  getPaysData(): Observable<any> {
    return this.http.get(environment.public_apiUrl + 'public/pays');
  }

  // getVilleDataById
  getVilleDataById(villeId: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/ville/' + villeId);

  }
  //updateVille

  updateVille(id, data): Observable<any> {
    return this.http.put(environment.apiUrl + 'params/update_ville/' + id, data);
  }
  //
  //insertVille
  insertVille(item): Observable<any> {
    return this.http.post(environment.apiUrl + 'params/add_ville', item);
  }
  //
  //deleteVille
  deleteVille(villeId: number): Observable<any> {
    return this.http.delete(environment.apiUrl + 'params/delete_ville/' + villeId);
  }
  //end ville

  // Debut du  kilometrage

  //  get kilometrage
  getKilometrageData(): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/kilometrage');
  }

  // getKilometrageDataById
  getKilometrageDataById(kilometrageIdId: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/kilometrage/' + kilometrageIdId);

  }
  //updateKilometrage

  updateKilometrage(id, data): Observable<any> {
    return this.http.put(environment.apiUrl + 'params/update_kilometrage/' + id, data);
  }
  //
  //insertKilometrage
  insertKilometrage(item): Observable<any> {
    return this.http.post(environment.apiUrl + 'params/add_kilometrage', item);
  }
  //
  //deleteKilometrage
  deleteKilometrage(kilometrageId: number): Observable<any> {
    return this.http.delete(environment.apiUrl + 'params/delete_kilometrage/' + kilometrageId);
  }

  // end kilometrgage

  getModelesByMarque(marqueId: number): Observable<Modele[]> {
    return this.http.get<Modele[]>(`${environment.apiUrl}params/marques/${marqueId}/modeles`);
  }


  //Debut caracteristiques
  getCaracteristiquesData(): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/caracteristiques-vehicule');
  }
  //
  getCouleurData(): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/couleur');
  }

  getTransmissionData(): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/transmission');
  }
  //
  getEtatData(): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/etats');
  }
  //

  //  Debut Distance

  getDistanceData(): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/distance');
  }
  // getDistanceDataById
  getDistanceDataById(prixId: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/distance/' + prixId);

  }
  //updateDistance

  updateDistance(id, data): Observable<any> {
    return this.http.put(environment.apiUrl + 'params/update_distance/' + id, data);
  }
  //
  //insertDistance
  insertDistance(item): Observable<any> {
    return this.http.post(environment.apiUrl + 'params/add_distance', item);
  }
  //
  //deleteDistance
  deleteDistance(distanceId: number): Observable<any> {
    return this.http.delete(environment.apiUrl + 'params/delete_distance/' + distanceId);
  }
  //end distance

  //
  getDeviseData(): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/devise');
  }

  //
  getAstuces(): Observable<any> {
    return this.http.get(environment.apiUrl + 'offer/article-categorie-astuce')
  }
  //
  getConseils(): Observable<any> {
    return this.http.get(environment.apiUrl + 'offer/article-categorie-conseil')
  }

  getActualites(): Observable<any> {
    return this.http.get(environment.apiUrl + 'offer/article-categorie-actu')
  }
  getAstuceDataById(astuceId: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'offer/astuce/' + astuceId);
  }//end getPrixDataById

  // modification des prix
  updateAstuce(id, data): Observable<any> {
    return this.http.put(environment.apiUrl + 'offer/update_astuce/' + id, data);
  }
  //end

  //insertion des prix
  insertAstuce(item): Observable<any> {
    return this.http.post(environment.apiUrl + 'offer/add_astuce', item);
  }
  deleteAstuce(astuceId: number): Observable<any> {
    return this.http.delete(environment.apiUrl + 'offer/delete_astuce/' + astuceId);
  }


  deletePhoto(photoId: number, item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'offer/photo/' + photoId + '/remove', item);
  }
  getCategorieAstuce(): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/categorie-articles');
  }
  // prix
  getPrixData(): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/prix');
  }
  //suppression des prix
  deletePrix(prixId: number): Observable<any> {
    return this.http.delete(environment.apiUrl + 'params/delete_prix/' + prixId);
  }// end

  //liste des prix par id
  getPrixDataById(prixId: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'params/prix/' + prixId);
  }//end getPrixDataById

  // modification des prix
  updatePrix(id, data): Observable<any> {
    return this.http.put(environment.apiUrl + 'params/update_prix/' + id, data);
  }
  //end

  //insertion des prix
  insertPrix(item): Observable<any> {
    return this.http.post(environment.apiUrl + 'params/add_prix', item);
  }
  //end

  //  recherche
  rechercheItems(dateDebut: string, dateFin: string, ville: string, marque: string, modele: string): Observable<any> {

    return this.http.get(environment.apiUrl + `/?dateDebut=${dateDebut}&dateFin=${dateFin}&ville=${ville}&marque=${marque}&modele=${modele}`);
  }
  ///end

  getAbonneList(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}abonnes`)

  }//fin getAbonneList
  getVideoList(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}videos`)

  }//fin getVideoList


  //get typeprofil list
  getTypeProfilList(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}type/collaborateurs`)

  }//fin getVideoList


  //créer contrat
  createContrat(item: any): Observable<any> {
    return this.http.post<any[]>(`${this.appService.getBaseUrl()}contrat`, item, this.appService.httpOptions)
      .pipe(
        catchError(this.appService.handleError<any[]>(``))
      )
  }
  //fin créer contrat

  // getRoleList
  //get typeprofil list
  getRoleList(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}type/collaborateurs`)

  }//fin getVideoList

  updatePassword(item: any): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}/password`, item)

  }
  //end

  //modifier user connected
  updateUserConnected(item: any): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}/modifier/identiter`, item)

  }
  //end modifier userconnected
  getArticleByRedateur(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}soumissions`)

  }//fin getVideoList
  getTaux(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}taux`)

  }
  validateArticleRedateur(item: any): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}soumission/change-status`, item)

  }

  updateNewsArticle(item: any): Observable<any> {
    return this.http.post<any[]>(`${environment.public_apiUrl}operation/update-soumission`, item)

  }

}
