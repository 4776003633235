<!-- Contacts model  -->
<div class="modal-header">
    <h4 class="text-center">Configuration du Taux de Rémunération par Vue</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-3 d-flex justify-content-center align-items-center">
    <div class="container d-flex justify-content-center align-items-center">
        <form class="mt-4">
            <div class="row mb-3">
                <label style="white-space: nowrap;" for="taux" class="col-sm-3 col-form-label">Code</label>
                <div class="col-md-12">
                    <input type="text" class="form-control" id="taux" [(ngModel)]="code" name="code" />
                </div>
            </div>
            <div class="row mb-3">
                <label style="white-space: nowrap;" for="taux" class="col-sm-3 col-form-label">Valeur</label>
                <div class="col-md-12">
                    <input type="number" class="form-control" id="taux" [(ngModel)]="taux" name="taux"
                        placeholder="Entrez le taux (ex: 1)" />
                </div>
            </div>
            <!-- <div class="row mb-3">
                <label style="white-space: nowrap;" for="vues" class="col-sm-3 col-form-label">Nombre de Vues:</label>
                <div class="col-md-12">
                    <input type="number" class="form-control" id="vues" [(ngModel)]="vues" name="vues"
                        placeholder="Entrez le nombre de vues (ex: 2000)" (input)="calculerRemuneration()" />
                </div>
            </div> -->

            <div class="row mb-3">
                <label style="white-space: nowrap;" for="vues" class="col-sm-3 col-form-label">Description</label>
                <div class="col-md-12">
                    <textarea type="text" class="form-control" id="description" [(ngModel)]="description"
                        name="description" placeholder="">
                 </textarea>
                </div>
            </div>
            <!-- <div class="row">
                <label style="white-space: nowrap;" for="remuneration" class="col-sm-3 col-form-label">Rémunération
                    Calculée:</label>
                <div class="col-md-12">
                    <p class="form-control-plaintext fw-bold">{{ remuneration | number }} FCFA</p>
                </div>
            </div> -->
            <div class="text-end">
                <button (click)="saveTauxByArticle()" type="submit" class="btn btn-success"> <i class="pi pi-save"
                        style="color: white"></i> Enregistrer</button>
            </div>
        </form>
    </div>
</div>