import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppService } from '../app/app.service';
import { catchError } from 'rxjs/operators';

import { Observable } from 'rxjs/index';

@Injectable()
export class PublicationService {

  constructor(private http: HttpClient, private appService: AppService) {
  }

  listePaiement(obj: any): Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}filtre-paiements`, obj,
      this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``)
        )
      )
  }
  //liste Publication
  listeArticles(): Observable<any> {
    return this.http.get(`${this.appService.getBaseUrl()}articles`,
      //  this.appService.httpOptions
    )
    // .pipe(
    //   //tap(_ => console.log(``)),
    //   catchError(this.appService.handleError<any>(``)
    //   )
    // )
  }//end listePublications

  //liste Articles
  listeMedias(obj: any): Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}medias`, obj,
      this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``)
        )
      )
  }//end listeArticles
  listeDetailsPeripherique(obj: any): Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}consultation`, obj,
      this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``)
        )
      )
  }
  //getArticleParPeriode

  getVuArticleParPeriode(obj: any): Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}/articles`, obj,
      this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``)
        )
      )
  }
  //end

  //creer Article
  creerArticle(item: any): Observable<any> {
    return this.http.post<any[]>(`${this.appService.getBaseUrl()}new/articles`, item
      // this.appService.httpOptions
    )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any[]>(``)
        )
      )
  }//end creerArticle

  //create immage
  saveImageArticle(fileToUpload: any, item: any): Observable<any> {
    const input: FormData = new FormData();
    if (fileToUpload !== null && fileToUpload !== undefined) {
      input.append("file", fileToUpload);
    }
    console.log(JSON.stringify(item));
    console.log(eval(item));
    let newitem = eval(item);
    input.append("data", newitem);
    //input.append("data", newitem);

    let localHeaders: any = new HttpHeaders({
      Accept: 'application/json'
    });
    return this.http.post<any[]>(`${this.appService.getBaseUrl()}new/articles`, input, { headers: localHeaders })
      .pipe(
        catchError(this.appService.handleError<any[]>(``))
      )
  }//fin saveImage 

  //edition de l'article
  editerArticle(item: any): Observable<any> {

    return this.http.post<any[]>(`${this.appService.getBaseUrl()}update/article`, item)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any[]>(``)
        )
      )
  }//end editerArticle


  //creer creerPublication
  supprimerPublication(item: any): Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}delete/article`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``)
        )
      )
  }//end creerPublication

  //supprimerMedia
  supprimerMedia(item: any): Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}delete/media`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``)
        )
      )
  }//supprimerMedian

  //liste Reporteur
  getReporter(): Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}get/reporteur`,
      this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``)
        )
      )
  }//end getReporter

  //liste languages
  getLanguages(): Observable<any> {
    return this.http.get(`${this.appService.getBaseUrl()}all/langages`,
      this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``)
        )
      )
  }//end getLanguages

  //creer creerPublication
  addMedia(fileToUpload: File, item: any): Observable<any> {
    /*   //  var input: any = new FormData();
       // const input: FormData = new FormData();
        //let data;
       if (fileToUpload !== null && fileToUpload !== undefined) {
          input.append("data", fileToUpload);
       }
        console.log("string fy"+JSON.stringify(item));
       // let newitem = eval(item);
       // console.log("eval"+ eval(item));
       // console.log("data input");
       input.append("data", JSON.stringify(item));
        console.log("data"+input.entries());
      
        //input.append("data", newitem);
       /*  for (var file of item) {
          input.append(file.name, file)
        } */


    const input: FormData = new FormData();
    if (fileToUpload !== null && fileToUpload !== undefined) {
      input.append("file", fileToUpload);
    }
    input.append("data", JSON.stringify(item));

    let localHeaders: any = new HttpHeaders({
      Accept: 'application/json'
    });


    return this.http.post<any[]>(`${this.appService.getBaseUrl()}new/media  `, input,
      { headers: localHeaders }
      // this.appService.httpOptions
    )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any[]>(``)
        )
      )
  }//end creerPublication

  //creer creerPublication
  listCategorie(): Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}categorie/articles  `,
      this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``)
        )
      )
  }//end creerPublication

  //listRessources
  listRessources(): Observable<any> {
    return this.http.get(`${this.appService.getBaseUrl()}types/ressources  `,
      this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``)
        )
      )
  }//end listRessources



  saveTaux(obj:any): Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}create-taux`,obj,
     this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``)
        )
      )
  }

  savePayement(obj:any): Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}valider-paiement`,obj,
     this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``)
        )
      )
  }
  updateTaux(obj:any): Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}update-taux`,obj,
     this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``)
        )
      )
  }




}

