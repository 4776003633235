import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from '../../../../shared/services/storage/localstorage.service';
import { OperationService } from '../../../../shared/services/operation/operation.service';
import { PublicationService } from '../../../../shared/services/publications/publication.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LibraryService } from 'src/app/shared/services/app/library.service';
import { ParameterService } from 'src/app/shared/services/http/parameters/parameters.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ConfirmationService } from 'primeng/api';
import { AddTauxConfigComponent } from '../add-taux-config/add-taux-config.component';
import { UpdateTauxConfigComponent } from '../update-taux-config/update-taux-config.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-taux-par-article',
  templateUrl: './taux-par-article.component.html',
  styleUrls: ['./taux-par-article.component.scss']
})

export class TauxParArticleComponent implements OnInit {


  items: any[] = [];
  pageStartIndex: number = 0;
  limitTable: number[] = [];
  page: number = 1;
  pageSize: number = 10;
  searchText: string = '';
  selectedStatut: string = '';
  selectedCategorie: string = '';
  statuts: string[] = ['En attente', 'Valide', 'Rejeté'];  // Liste des statuts
  categories: string[] = ['Monuments', 'Voodoo', 'Musée', 'Destinations'];  // Liste des catégories
  filteredItems: any[] = [];  // Liste filtrée
  isLoading: boolean = false;
  visible: boolean = false;
  rejetItems: any;
  motif: any;
  user_id: any;
  constructor(private router: Router,
    private loaderService: NgxUiLoaderService,
    private libraryService: LibraryService,
    private localStorageService: LocalStorageService,
    private paramService: ParameterService,
    private modalService: NgbModal,
    private utils: UtilsService,
    private confirmationService: ConfirmationService,
    private pubService: PublicationService,
    private FecthDataService: FecthDataService,

  ) { 
    this.FecthDataService.actionCreated$.subscribe(() => {
      // Met à jour la liste après la création réussie d'un glossaire
      this.getTaux();
    });  
  }

  ngOnInit(): void {
    this.user_id = this.localStorageService.getUserConnected();
    console.log("user_id", this.user_id);
    this.getCategorie();

  }

  //récupérer la liste des avis
  getTaux(): void {
    this.isLoading = true;
    //this.loaderService.start();
    this.items = [];

    this.paramService.getTaux().subscribe(
      (result: any) => {
        this.isLoading = false;
        let resp = result;
        //this.loaderService.stop();

        if (resp === null || resp === undefined) {
          this.items = this.items;
          return;
        }
        if (resp.status === "error") {
          this.utils.notifyErrorMessage(result.message, "Article Rédateur");
          this.items = this.items;
          return;
        }
        this.items = resp.data;
        this.filteredItems = this.items;
        console.log(this.items);
      },
      err => {
        this.loaderService.stop();
        this.isLoading = false;
        return;
      }
    );
  } //end getAvisList
  configurerTauxParVue() {
    // this.localStorageService.saveTauxArticle(item)
     this.modalService.open(AddTauxConfigComponent, {
        centered: true,
        backdrop: 'static', 
         size:"xl  "
 
        });
   }
  editAbonne(item:any) {
    this.localStorageService.saveTauxArticle(item)
    this.modalService.open(UpdateTauxConfigComponent, {
      centered: true,
      backdrop: 'static', 
       size:"xl  "

      });
  }


  goToVisitesAbonne(item: any) {
    this.localStorageService.saveAbonne(item);
    //console.log(item);

    this.router.navigate(["/admin/activites/abonne-visite"]);
  }


  goToDonsAbonne(item: any) {
    this.localStorageService.saveAbonne(item);
    //console.log(item);

    this.router.navigate(["/admin/activites/abonne-don"]);

  }

  filterData() {
    this.filteredItems = this.items.filter(item => {
      const matchesSearch = this.searchText ? item.user.nom.toLowerCase().includes(this.searchText.toLowerCase()) : true;
      const matchesStatut = this.selectedStatut ? item.statut === this.selectedStatut : true;
      const matchesCategorie = this.selectedCategorie ? item.category.nom === this.selectedCategorie : true;

      return matchesSearch && matchesStatut && matchesCategorie;
    });
  }


  showDialog(item: any) {
    this.rejetItems = item;
    this.visible = true;
  }

  editItem(item: any) {
    this.localStorageService.saveArticleByRedateur(item);
    this.router.navigate(['/parametres/edit-article-redateur'])
    //  console.log('Éditer l\'article', itemId);
  }

  closeMotifDialog() {
    this.visible = false;
    this.motif = ''; // Réinitialiser le motif
  }

  listCategorie: any = [];
  getCategorie(): void {

    //this.loaderService.start();
    this.pubService.listCategorie().subscribe(
      (result: any) => {
        console.log(result)

        this.listCategorie = result.data;
        let defaultCat = { id: 0, libelle: "Veuillez sélectionner une langue" };
        this.listCategorie.unshift(defaultCat);

      },
      err => {

        return;
      }
    );
  }
}
export class FecthDataService {
  private ServiceCreatedSubject = new BehaviorSubject<boolean>(false);
  actionCreated$ = this.ServiceCreatedSubject.asObservable();

  notifySeriviceCreated() {
    this.ServiceCreatedSubject.next(true);
  }
}