<div class="row">
    <div class="col-sm-12">
        <app-card cardTitle="taux par vue" cardClass="card-datatable" [options]="false">
            <!-- Row for Search and Filters -->
            <div class="row align-items-center m-l-0 mb-2">
                <!-- <div class="col-sm-3">
                    <input (input)="filterData()" type="text" class="form-control"
                        placeholder="Rechercher par utilisateur..." [(ngModel)]="searchText">
                </div>
                <div class="col-sm-3">
                    <select class="form-control" [(ngModel)]="selectedStatut" (change)="filterData()">
                        <option value="">Tous les statuts</option>
                        <option *ngFor="let statut of statuts" [value]="statut">{{ statut }}</option>
                    </select>
                </div>
                <div class="col-sm-3">
                    <select class="form-control" [(ngModel)]="selectedCategorie" (change)="filterData()">
                        <option value="">Toutes les catégories</option>
                        <option *ngFor="let categorie of listCategorie" [value]="categorie.nom">{{ categorie.nom }}</option>
                    </select>
                </div> -->
                <div class="col-sm-12 text-end">
                    <button class="btn btn-primary" (click)="configurerTauxParVue()">Ajouter</button>
                </div>
            </div>

            <!-- Table -->
            <div class="table-responsive">
                <table datatable class="table table-striped table-bordered nowrap table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Code</th>
                            <th>valeur</th>
                            <th>Description</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="isLoading">
                            <td class="text-center" colspan="6">Veuillez patienter pendant le chargement des données...
                            </td>
                        </tr>

                        <tr *ngFor="let item of filteredItems">
                            <td class="text-center">{{ item.id }}</td>
                            <td>{{ item.code }}</td>
                            <td>{{ item?.valeur}}</td>
                            <td>{{ item?.description }}</td>
                            <td class="text-center">
                                <!-- <button class="btn btn-success btn-sm btn-spacing" (click)="validateItem(item)">
                                    <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                </button>
                                <button class="btn btn-danger btn-sm btn-spacing" (click)="showDialog(item)">
                                    <i class="fa fa-recycle" aria-hidden="true"></i>
                                </button> -->
                                <button style="color: white;" class="btn btn-info btn-sm" (click)="editAbonne(item)">
                                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>

                    </tbody>
                </table>

                <!-- Pagination -->
                <ngb-pagination class="float-right mb-2" [(page)]="page" [pageSize]="pageSize"
                    [collectionSize]="filteredItems.length"></ngb-pagination>
            </div>
        </app-card>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>
<p-confirmDialog>
</p-confirmDialog>