import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PublicationService } from 'src/app/shared/services/publications/publication.service';
import { LocalStorageService } from 'src/app/shared/services/storage/localstorage.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { FecthDataService } from '../taux-par-article/taux-par-article.component';

@Component({
  selector: 'app-add-taux-config',
  templateUrl: './add-taux-config.component.html',
  styleUrls: ['./add-taux-config.component.scss']
})

export class AddTauxConfigComponent implements OnInit {
  code: any;
  description: any;
  taux: number = 0;
  vues: number = 0;
  remuneration: number = 0;
  article_id: any;
  user: any;
  constructor(
    private modalService: NgbModal,
    private localStorage: LocalStorageService,
    private publicationService: PublicationService,
    private utils: UtilsService,
    private loader: NgxUiLoaderService,
    private FecthDataService: FecthDataService,

  ) { }

  ngOnInit(): void {
    this.article_id = this.localStorage.getTauxArticle();
    console.log("  this.article_id", this.article_id);
    this.user = this.localStorage.getUserConnected();

  }
  calculerRemuneration(): void {
    if (this.taux > 0 && this.vues > 0) {
      this.remuneration = this.vues * this.taux;
    } else {
      this.remuneration = 0;
    }
  }
  close() {
    this.modalService.dismissAll();
  }

  saveTauxByArticle() {

    if (!this.code) {
      this.utils.notifyInfoMessage("", "Le code est obligatoire");
      return;
    }

    const requestData = {
      code: this.code,
      valeur: this.taux,
      description: this.description,
      id_user: this.user.data.id
    }
    this.loader.start();

    this.publicationService.saveTaux(requestData).subscribe(
      (result: any) => {
        console.log("la reponse du webservice", result);
        this.loader.stop();
        this.FecthDataService.notifySeriviceCreated();

        this.modalService.dismissAll();
      },
      (err) => {
        console.log("une erreur est survenu", err);
        this.loader.stop();
        this.modalService.dismissAll();

        return;
      }
    );
  }
}
