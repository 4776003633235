import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {



  constructor(private http: HttpClient) { }

  //enregistre un utilisateur connecte
  public saveUser(obj: any): void {
    this.saveToSession("M_MY_WORLD_USER", obj);
  };
  //retourne un user
  public getUser(): any {
    return this.readFromSession("M_MY_WORLD_USER");
  };


  //enregistre un groupe
  public saveGroupe(obj: any): void {
    this.saveToSession("M_MY_WORLD_GROUPE", obj);
  };

  //retourne un groupe
  public getGroupe(): any {
    return this.readFromSession("M_MY_WORLD_GROUPE");
  };
  //enregistre un profil
  public saveProfil(obj: any): void {
    this.saveToSession("M_MY_WORLD_PROFIL", obj);
  };
  //retourne un profil
  public getProfil(): any {
    return this.readFromSession("M_MY_WORLD_PROFIL");
  };

  //enregistre un client
  public saveClient(obj: any): void {
    this.saveToSession("M_MY_WORLD_CLIENT", obj);
  };
  //retourne un client
  public getClient(): any {
    return this.readFromSession("M_MY_WORLD_CLIENT");
  };




  //enregistre un profil
  public saveAdmin(obj: any): void {
    this.saveToSession("M_MY_WORLD_ADMIN", obj);
  };
  //retourne un profil
  public getAdmin(): any {
    return this.readFromSession("M_MY_WORLD_ADMIN");
  };


  //enregistre un profil à supprimer
  public saveDataDeleteProfil(obj: any): void {
    this.saveToSession("M_MY_WORLD_ADMIN", obj);
  };

  //retourne un profil à supprimer
  public getDataDeleteProfil(): any {
    return this.readFromSession("M_MY_WORLD_ADMIN");
  };




  //enregistre un pays
  public savePays(obj: any): void {
    this.saveToSession("M_MY_WORLD_PAYS", obj);
  };

  //retourne un pays
  public getPays(): any {
    return this.readFromSession("M_MY_WORLD_PAYS");
  };

  //enregistre un pays à supprimer
  public savedatadeletePays(obj: any): void {
    this.saveToSession("M_MY_WORLD_PAYS", obj);
  };

  //retourne un pays supprimé
  public getdatadeletePays(): any {
    return this.readFromSession("M_MY_WORLD_PAYS");
  };

  //enregistre une destination
  public saveDestination(obj: any): void {
    this.saveToSession("M_MY_WORLD_DESTINATION", obj);
  };

  //retourne un vaudoun
  public getDestination(): any {
    return this.readFromSession("M_MY_WORLD_DESTINATION");
  };


  //enregistre un vaudoun à supprimer
  public savedatadeleteDestination(obj: any): void {
    this.saveToSession("M_MY_WORLD_DESTINATION", obj);
  };

  //retourne un vaudoun supprimé
  public getdatadeleteDestination(): any {
    return this.readFromSession("M_MY_WORLD_DESTINATION");
  };


  //enregistre un vaudoun
  public saveVaudoun(obj: any): void {
    this.saveToSession("M_MY_WORLD_VAUDOUN", obj);
  };

  //retourne un vaudoun
  public getVaudoun(): any {
    return this.readFromSession("M_MY_WORLD_VAUDOUN");
  };

  //enregistre un vaudoun à supprimer
  public savedatadeleteVaudoun(obj: any): void {
    this.saveToSession("M_MY_WORLD_VAUDOUN", obj);
  };

  //retourne un vaudoun supprimé
  public getdatadeleteVaudoun(): any {
    return this.readFromSession("M_MY_WORLD_VAUDOUN");
  };

  //enregistre une video
  public saveVideo(obj: any): void {
    this.saveToSession("M_MY_WORLD_VIDEO", obj);
  };

  //retourne une vidéo
  public getVideo(): any {
    return this.readFromSession("M_MY_WORLD_VIDEO");
  };

  //enregistre une video à supprimer
  public savedatadeleteVideo(obj: any): void {
    this.saveToSession("M_MY_WORLD_VIDEO", obj);
  };

  //retourne une vdeo supprimée
  public getdatadeleteVideo(): any {
    return this.readFromSession("M_MY_WORLD_VIDEO");
  };

  //retourne un contrat
  public getContrat(): any {
    return this.readFromSession("M_MY_WORLD_CONTRAT");
  };

  //enregistre un abonne
  public saveAbonne(obj: any): void {
    this.saveToSession("M_MY_WORLD_ABONNE", obj);
  };
  //retourne un abonne
  public getAbonne(): any {
    return this.readFromSession("M_MY_WORLD_ABONNE");
  };

  public saveDetailsVueArticle(obj: any): void {
    this.saveToSession("M_MY_WORLD_DETAILS_VUE_ARTICLE", obj);
  };
  //retourne un abonne
  public getDetailsVueArticle(): any {
    return this.readFromSession("M_MY_WORLD_DETAILS_VUE_ARTICLE");
  };
  public saveTauxArticle(obj: any): void {
    this.saveToSession("M_MY_WORLD_ARTICLETAUXSAVE", obj);
  };
  //retourne un abonne
  public getTauxArticle(): any {
    return this.readFromSession("M_MY_WORLD_ARTICLETAUXSAVE");
  };

  public saveArticle(obj: any): void {
    this.saveToSession("M_MY_WORLD_ARTICLE", obj);
  };
  //retourne un client
  public getArticle(): any {
    return this.readFromSession("M_MY_WORLD_ARTICLE");
  };



  //enregistre un media
  public saveMedia(obj: any): void {
    this.saveToSession("M_MY_WORLD_DESTINATION", obj);
  };

  //retourne un media
  public getMedia(): any {
    return this.readFromSession("M_MY_WORLD_DESTINATION");
  };


  //enregistre un profil
  public saveUserConnected(obj: any): void {
    this.saveToSession("M_MY_WORLD_USER_CONNECTED", obj);
  };
  //retourne un profil
  public getUserConnected(): any {
    return this.readFromSession("M_MY_WORLD_USER_CONNECTED");
  };

















  ////////////////////////////////////////////////////////////////


  //save type intervention data to edit
  public saveTypeInterventionToEdit(obj: any): void {
    this.saveToSession("GNSP_TYPE_INTERVENTION_TO_EDIT", obj);
  }

  //retourne type intervention to edit
  public getTypeInterventionToEdit(): any {
    return this.readFromSession("GNSP_TYPE_INTERVENTION_TO_EDIT");
  };

  //save type moyen d'appel data to edit
  public saveMoyenAppelToEdit(obj: any): void {
    this.saveToSession("GNSP_MOYEN_AAPPEL_TO_EDIT", obj);
  }

  //retourne moyen appel to edit
  public getMoyenAppelToEdit(): any {
    return this.readFromSession("GNSP_MOYEN_AAPPEL_TO_EDIT");
  };

  //save  hopital data to edit
  public savehopitalToEdit(obj: any): void {
    this.saveToSession("GNSP_HOPITAL_TO_EDIT", obj);
  }

  //retourne  hopital to edit
  public gethopitalToEdit(): any {
    return this.readFromSession("GNSP_HOPITAL_TO_EDIT");
  };

  //save  groupement data to edit
  public savegroupementToEdit(obj: any): void {
    this.saveToSession("GNSP_GROUPEMENT_TO_EDIT", obj);
  }

  //retourne  groupement to edit
  public getgroupementToEdit(): any {
    return this.readFromSession("GNSP_GROUPEMENT_TO_EDIT");
  };
  //save  compagnie data to edit
  public savecompagnieToEdit(obj: any): void {

    this.saveToSession("GNSP_COMPAGNIE_TO_EDIT", obj);
  }

  //retourne  compagnie to edit
  public getcompagnieToEdit(): any {

    return this.readFromSession("GNSP_COMPAGNIE_TO_EDIT");
  };
  //save  caserne data to edit
  public savecaserneToEdit(obj: any): void {
    this.saveToSession("GNSP_CASERNE_TO_EDIT", obj);
  }

  //retourne  caserne to edit
  public getcaserneToEdit(): any {
    return this.readFromSession("GNSP_CASERNE_TO_EDIT");
  };
  //save  alerte motivée data to edit
  public saveAlerteToEdit(obj: any): void {
    this.saveToSession("GNSP_ALERTE_TO_EDIT", obj);
  }

  //retourne  alerte motivée to edit
  public getAlerteToEdit(): any {
    return this.readFromSession("GNSP_ALERTE_TO_EDIT");
  };
  //save vixtime statistique
  public saveVictimesStatistiques(obj: any): void {
    this.saveToSession("GNSP_VICTIME_STAT_TO_EDIT", obj);
  }

  //retourne  victime statistique to edit
  public getVictimesStatistiques(): any {
    return this.readFromSession("GNSP_VICTIME_STAT_TO_EDIT");
  };

  //save vehicule attribution to edit
  public saveVéhiculeAttribution(obj: any): void {
    this.saveToSession("GNSP_VEHICULE_ATTRIBUTION_TO_EDIT", obj);
  }

  //retourne  vehicule attribution to edit
  public getVéhiculeAttribution(): any {
    return this.readFromSession("GNSP_VEHICULE_ATTRIBUTION_TO_EDIT");
  };
  //save profil attribution  to edit
  public saveProfilAttribution(obj: any): void {
    this.saveToSession("GNSP_PROFIL_ATTRIBUTION_TO_EDIT", obj);
  }

  //retourne  profil attribution to edit
  public getProfilAttribution(): any {
    return this.readFromSession("GNSP_PROFIL_ATTRIBUTION_TO_EDIT");
  };
  //save victime opération to edit
  public saveVictimeOpération(obj: any): void {
    this.saveToSession("GNSP_VICTIME_OPERATION_TO_EDIT", obj);
  }

  //retourne  victime intervention to edit
  public getVictimeIntervention(): any {
    return this.readFromSession("GNSP_VICTIME_OPERATION_TO_EDIT");
  };


  public saveAnnonceursOpération(obj: any): void {
    this.saveToSession("VENTEVOITURE_OPERATION_TO_EDIT", obj);
  }

  //retourne  victime intervention to edit
  public getAnnonceurOperation(): any {
    return this.readFromSession("VENTEVOITURE_OPERATION_TO_EDIT");
  };
  //save offre to edit
  public saveOffreItem(obj: any): void {
    this.saveToSession("MARKETO_OFFRE_TO_EDIT", obj);
  }//end saveOffreItem

  //retourne  offre to edit
  public getOffreItem(): any {
    return this.readFromSession("MARKETO_OFFRE_TO_EDIT");
  }
  public savePrixItem(obj: any): void {
    this.saveToSession("MARKETO_PRIX_TO_EDIT", obj);
  }//end savePrixItem

  //retourne  prix to edit
  public getPrixItem(): any {
    return this.readFromSession("MARKETO_PRIX_TO_EDIT");
  }

  public saveKilometrageItem(obj: any): void {
    this.saveToSession("MARKETO_KILOMETRAGE_TO_EDIT", obj);
  }//end saveKilometrageItem

  //retourne  kilometrage to edit
  public getKilometrageItem(): any {
    return this.readFromSession("MARKETO_KILOMETRAGE_TO_EDIT");
  }

  public saveDistanceItem(obj: any): void {
    this.saveToSession("MARKETO_DISTANCE_TO_EDIT", obj);
  }//end saveDistanceItem

  //retourne  kilometrage to edit
  public getDistanceItem(): any {
    return this.readFromSession("MARKETO_DISTANCE_TO_EDIT");
  }

  public saveMarqueItem(obj: any): void {
    this.saveToSession("MARKETO_MARQUE_TO_EDIT", obj);
  }//end saveMarqueItem

  //retourne  marque to edit
  public getMarqueItem(): any {
    return this.readFromSession("MARKETO_MARQUE_TO_EDIT");
  }



  public saveModelItem(obj: any): void {
    this.saveToSession("MARKETO_MODEL_TO_EDIT", obj);
  }//end saveMarqueItem

  //retourne  marque to edit
  public getModelItem(): any {
    return this.readFromSession("MARKETO_MODEL_TO_EDIT");
  }
  public saveVilleItem(obj: any): void {
    this.saveToSession("MARKETO_VILLE_TO_EDIT", obj);
  }//end saveVilleItem

  //retourne  ville to edit
  public getVilleItem(): any {
    return this.readFromSession("MARKETO_VILLE_TO_EDIT");
  }

  public saveAstuceItem(obj: any): void {
    this.saveToSession("MARKETO_ASTUCE_TO_EDIT", obj);
    console.error('Erreur lors de la sérialisation des données pour le stockage local :');

  }//end saveVilleItem

  //retourne  ville to edit
  public getAstuceItem(): any {
    return this.readFromSession("MARKETO_ASTUCE_TO_EDIT");
  }


  /* save order listing */


  public saveCategorieItem(obj: any): void {
    this.saveToSession("MARKETO_CATEGORIE_TO_VEHICULE", obj);
    // console.error('Erreur lors de la sérialisation des données pour le stockage local :');

  }//end saveCategorieItem

  //retourne  getCategorieItem 
  public getCategorieItem(): any {
    return this.readFromSession("MARKETO_CATEGORIE_TO_VEHICULE");
  }


  public saveTypeCategorieItem(obj: any): void {
    this.saveToSession("MARKETO_TYPE_TO_VEHICULE", obj);
    // console.error('Erreur lors de la sérialisation des données pour le stockage local :');

  }//end saveCategorieItem

  //retourne  getCategorieItem 
  public getTypeCategorieItem(): any {
    return this.readFromSession("MARKETO_TYPE_TO_VEHICULE");
  }


  public saveVillEToVehiculeItem(obj: any): void {
    this.saveToSession("MARKETO_VILLE_TO_VEHICULE", obj);
    // console.error('Erreur lors de la sérialisation des données pour le stockage local :');

  }//end saveCategorieItem

  //retourne  getCategorieItem 
  public getVilleTovehiculeItem(): any {
    return this.readFromSession("MARKETO_VILLE_TO_VEHICULE");
  }


  public saveCaracteristiqueToVehiculeItem(obj: any): void {
    this.saveToSession("MARKETO_CARACTERISTIQUE_TO_VEHICULE", obj);
    // console.error('Erreur lors de la sérialisation des données pour le stockage local :');

  }//end saveCategorieItem

  //retourne  getCategorieItem 
  public getCaracteristiqueTovehiculeItem(): any {
    return this.readFromSession("MARKETO_CARACTERISTIQUE_TO_VEHICULE");
  }
  /*end sauvegarde des listing*/


  public saveCouleurToVehiculeItem(obj: any): void {
    this.saveToSession("MARKETO_COULEUR_TO_VEHICULE", obj);
    // console.error('Erreur lors de la sérialisation des données pour le stockage local :');

  }//end saveCategorieItem

  //retourne  getCategorieItem 
  public getCouleurTovehiculeItem(): any {
    return this.readFromSession("MARKETO_COULEUR_TO_VEHICULE");
  }

  public saveTransmissionToVehiculeItem(obj: any): void {
    this.saveToSession("MARKETO_TRANSMISSION_TO_VEHICULE", obj);
    // console.error('Erreur lors de la sérialisation des données pour le stockage local :');

  }//end saveCategorieItem

  //retourne  getCategorieItem 
  public getTransmissionTovehiculeItem(): any {
    return this.readFromSession("MARKETO_TRANSMISSION_TO_VEHICULE");
  }

  public saveEtatToVehiculeItem(obj: any): void {
    this.saveToSession("MARKETO_ETAT_TO_VEHICULE", obj);
    // console.error('Erreur lors de la sérialisation des données pour le stockage local :');

  }//end saveCategorieItem

  //retourne  getCategorieItem 
  public getEtatTovehiculeItem(): any {
    return this.readFromSession("MARKETO_ETAT_TO_VEHICULE");
  }

  public saveMarqueToVehiculeItem(obj: any): void {
    this.saveToSession("MARKETO_MARQUE_TO_VEHICULE", obj);
    // console.error('Erreur lors de la sérialisation des données pour le stockage local :');

  }//end saveCategorieItem

  //retourne  getCategorieItem 
  public getMarqueTovehiculeItem(): any {
    return this.readFromSession("MARKETO_MARQUE_TO_VEHICULE");
  }

  public savePrixToVehiculeItem(obj: any): void {
    this.saveToSession("MARKETO_PRIX_TO_VEHICULE", obj);
    // console.error('Erreur lors de la sérialisation des données pour le stockage local :');

  }//end saveCategorieItem

  //retourne  getCategorieItem 
  public getPrixTovehiculeItem(): any {
    return this.readFromSession("MARKETO_PRIX_TO_VEHICULE");
  }

  public saveKilometrageToVehiculeItem(obj: any): void {
    this.saveToSession("MARKETO_KILOMETRAGE_TO_VEHICULE", obj);
    // console.error('Erreur lors de la sérialisation des données pour le stockage local :');

  }//end saveCategorieItem

  //retourne  getCategorieItem 
  public getKilometrageTovehiculeItem(): any {
    return this.readFromSession("MARKETO_KILOMETRAGE_TO_VEHICULE");
  }


  public saveDistanceToVehiculeItem(obj: any): void {
    this.saveToSession("MARKETO_DISTANCE_TO_VEHICULE", obj);
    // console.error('Erreur lors de la sérialisation des données pour le stockage local :');

  }//end saveCategorieItem

  //retourne  getCategorieItem 
  public getDistanceTovehiculeItem(): any {
    return this.readFromSession("MARKETO_DISTANCE_TO_VEHICULE");
  }

  public saveDeviseToVehiculeItem(obj: any): void {
    this.saveToSession("MARKETO_DEVISE_TO_VEHICULE", obj);
    // console.error('Erreur lors de la sérialisation des données pour le stockage local :');

  }//end saveCategorieItem

  //retourne  getCategorieItem 
  public getDeviseTovehiculeItem(): any {
    return this.readFromSession("MARKETO_DEVISE_TO_VEHICULE");
  }
  /*end sauvegarde des listing*/



  public saveArticleByRedateur(obj: any): void {
    this.saveToSession("M_MY_WORLD_ARTICLE_REDATEUR", obj);
  };
  //retourne un abonne
  public getArticleByRedateur(): any {
    return this.readFromSession("M_MY_WORLD_ARTICLE_REDATEUR");
  };








  private saveToSession(key: string, value: any): void {
    var stringified = unescape(encodeURIComponent(JSON.stringify(value)));
    var jsonValue = btoa(stringified);
    localStorage.setItem(key, jsonValue);
  };



  private readFromSession(key: any): any {
    let result = null; let decoded: string = "";
    try {
      let json: any = localStorage.getItem(key);
      decoded = decodeURIComponent(escape(atob(json)));
      result = JSON.parse(decoded);
    } catch (e) {
      result = decoded;
    }
    return result;
  };

}