import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from '../../../../shared/services/storage/localstorage.service';
import { PublicationService } from '../../../../shared/services/publications/publication.service';
import { UtilsService } from '../../../../shared/services/utils.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FecthDataService } from '../taux-par-article/taux-par-article.component';

@Component({
  selector: 'app-update-taux-config',
  templateUrl: './update-taux-config.component.html',
  styleUrls: ['./update-taux-config.component.scss']
})

export class UpdateTauxConfigComponent implements OnInit {
  code: any;
  description: any;
  taux: number = 0;
  vues: number = 0;
  remuneration: number = 0;
  article_id: any;
  user: any;
items:any
  constructor(
    private modalService: NgbModal,
    private localStorage: LocalStorageService,
    private publicationService: PublicationService,
    private utils: UtilsService,
    private loader: NgxUiLoaderService,
    private FecthDataService: FecthDataService,


  ) { }

  ngOnInit(): void {
    this.items = this.localStorage.getTauxArticle();
    console.log("items",this.items);
    
    this.code = this.items.code;
    this.description = this.items.description;
    this.taux = this.items.valeur;
    console.log("  this.article_id", this.article_id);
    this.user = this.localStorage.getUserConnected();
    console.log("  this.user", this.user);

  }
  calculerRemuneration(): void {
    if (this.taux > 0 && this.vues > 0) {
      this.remuneration = this.vues * this.taux;
    } else {
      this.remuneration = 0;
    }
  }
  close() {
    this.modalService.dismissAll();
  }

  saveTauxByArticle() {

    if (!this.code) {
      this.utils.notifyInfoMessage("", "Le code est obligatoire");
      return;
    }
    const requestData = {
      code: this.code,
      valeur: this.taux,
      description: this.description,
      taux_id:this.localStorage.getTauxArticle()?.id,
      id_user: this.user.data.id

    }
    this.loader.start();

    this.publicationService.updateTaux(requestData).subscribe(
      (result: any) => {
        console.log("la reponse du webservice", result);
        this.loader.stop();
        this.modalService.dismissAll();
        this.FecthDataService.notifySeriviceCreated();

      },
      (err) => {
        console.log("une erreur est survenu", err);
        this.loader.stop();
        this.modalService.dismissAll();

        return;
      }
    );
  }
}